import React from "react";
import SystemContext from "./SystemContext";

class SystemState extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            system: null,
            user: {},
            loading: false,
            token: "",
        };
        window.$portalId = 1;
    }

    /// loads when page loads
    componentWillMount() {
        // if we have the localstorage info, then set the state using it
        let info = localStorage.getItem("user_info") && JSON.parse(window.atob(localStorage.getItem("user_info")));
        if (info !== null) this.setSystemState(info);
    }

    /// sets the loading var for api processing
    setLoading = (boo) => {
        this.setState({ loading: boo });
    };

    /// accepts a user object and serializes it to localstorage
    setUser = (user) => {
        localStorage.setItem("user_info", window.btoa(JSON.stringify(user)));
        this.setSystemState(user);
    };

    /// sets various state vars for the site based on the user object
    setSystemState = (user) => {
        this.setState({
            user,
        });
    };

    // Get User
    getUser = () => {
        try {
            // if user is defined, then just return it
            if (this.state.user.id !== undefined) {
                return this.state.user;
            }

            return null;
        } catch (err) {
            localStorage.removeItem("user_info");
            return null;
        }
    };

    logout = () => {
        localStorage.removeItem("user_info");
        this.setState(
            {
                user: {},
                loading: false,
            },
            () => {
                window.location.href = "/login?logoff=1";
            }
        );
    };

    render() {
        return (
            <SystemContext.Provider
                value={{
                    system: this.state.system,
                    user: this.state.user,
                    loading: this.state.loading,

                    setUser: this.setUser,
                    getUser: this.getUser,
                    setLoading: this.setLoading,
                    logout: this.logout,
                }}
            >
                {this.props.children}
            </SystemContext.Provider>
        );
    }
}

export default SystemState;

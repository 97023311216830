import React from "react";
import { Route, Routes } from "react-router-dom";
import Layout from "components/SimpleLayout";
import Queue from "./queue";
import Verify from "./verify";

const Home = () => {
    return (
        <Layout>
            <Routes>
                <Route path="/verify/:id" element={<Verify />} />
                <Route path="*" element={<Queue />} />
            </Routes>
        </Layout>
    );
};

export default Home;

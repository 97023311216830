import React, { useContext, useEffect } from "react";
import { Route, Routes, Navigate, useLocation } from "react-router-dom";
import SystemContext from "context/SystemContext";
import Home from "./modules/home";
import PageNotFound from "./modules/misc/404";
import Login from "./modules/misc/login";

export default function App() {
    const ctx = useContext(SystemContext);
    const location = useLocation();
    const authUser = ctx.getUser();

    useEffect(() => {
        // per environment, then add class to change the bg color
        if (window.location.href.indexOf("localhost") > -1) document.getElementById("root").className += " root-dev";
        if (window.location.href.indexOf("staging") > -1) document.getElementById("root").className += " root-staging";
    }, []);

    // forward to the landing page
    if (location.pathname === "/") {
        if (authUser === null) {
            return <Navigate to={"/login"} />;
        } else {
            return <Navigate to={"/home"} />;
        }
    }

    return (
        <div id="app-main" className="app-main">
            <Routes>
                <Route path={`home/*`} element={<Home />} />
                <Route path={`login/:token`} element={<Login />} />
                <Route path={`login/`} element={<Login />} />
                <Route path="*" element={<PageNotFound />} />
            </Routes>
        </div>
    );
}

import { notification } from "antd";
import { Messaging } from "./notifications";

export const notify = {
    success,
    error,
};

function success(m, callback) {
    notification.success({
        message: Messaging.success(),
        description: m,
        placement: "topRight",
    });
    if (callback) callback();
}

function error(m, callback) {
    console.log(m);
    if (m.errors) m = m.errors;
    if (typeof m === "object") m = m.toString();

    notification.error({
        message: Messaging.error(),
        description: m,
    });
    if (callback) callback();
}

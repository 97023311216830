import React, { useEffect, useContext, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { credentialService } from "services/CredentialService";
import Layout from "components/SimpleLayout";
import { Card, Spin, Alert } from "antd";
import SystemContext from "context/SystemContext";

const Login = () => {
    const ctx = useContext(SystemContext);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [err, setErr] = useState("");
    const { token } = useParams();

    useEffect(() => {
        if (!token) {
            setErr("Unauthorized");
            return;
        }

        setLoading(true);
        credentialService
            .tokenLogin(token)
            .then((data) => {
                ctx.setUser(data);
                navigate("/home");
            })
            .catch((err) => setErr(err))
            .finally(() => setLoading(false));
    }, []);

    return (
        <Layout>
            <Card className="w-50 m-auto">
                {loading && (
                    <div className="text-center">
                        <h2>Logging you in...</h2>

                        <Spin size="large" />
                    </div>
                )}

                {!loading && err === "" && (
                    <div className="text-center">
                        <h2>Loading your profile...</h2>

                        <Spin size="large" />
                    </div>
                )}

                {!loading && err !== "" && (
                    <div className="p-3">
                        <div className="app-login-header mb-4">
                            <h1>No Access</h1>
                        </div>

                        <Alert message="No Access" description={`Reason: ${err}`} type="error" showIcon />
                    </div>
                )}
            </Card>
        </Layout>
    );
};

export default Login;

import React from "react";
import img from "images/error.jpg";
import Layout from "components/SimpleLayout";
import { Card } from "antd";

const Error404 = () => (
    <Layout>
        <Card className="w-60 m-auto text-center">
            <img src={img} alt="Not Found" className="img-fluid" />
            <h2 className="text-center">Page Not Found (404)</h2>
        </Card>
    </Layout>
);

export default Error404;

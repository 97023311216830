import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Card, Form, Input, Button, Radio, Descriptions, Skeleton, DatePicker, Alert } from "antd";
import { credentialService } from "services/CredentialService";
import { notify } from "util/Notify";
import moment from "moment";

export default function Verify() {
    const navigate = useNavigate();
    const { id } = useParams();
    const [form] = Form.useForm();
    const [item, setItem] = useState();
    const [fields, setFields] = useState([]);
    const [loading, setLoading] = useState(true);
    const [submitting, setSubmitting] = useState(false);

    useEffect(() => {
        setLoading(true);

        // if it's a bad id, then get outta here
        if (!id) navigate("/home");

        // get list of agencies
        credentialService
            .getVerification(id)
            .then((data) => {
                if (data.claimData?.length > 0) {
                    const f = JSON.parse(data.claimData);
                    const fi = f.filter((o) => o.name !== "certification" && o.name !== "customernumber");

                    if (f.customernumber) data.customerNumber = f.customernumber;
                    if (f.credential) data.credentialNumber = f.credential;

                    setFields(fi);
                }

                setItem(data);
            })
            .catch((err) => {
                console.log(err);
                navigate("/home");
            })
            .finally(() => setLoading(false));
    }, []);

    // set the values of the fields
    useEffect(() => {
        let p = fields.reduce((a, v) => ({ ...a, [v.name]: v.value }), {});
        form.setFieldsValue({ ...p });
    }, [fields]);

    useEffect(() => {
        if (!item) return;
        form.setFieldsValue({
            notes: item.notes,
            expiresOn: item.expiresOn ? moment(item.expiresOn) : "",
            status: item.status > 1 ? item.status : null,
        });
    }, [item]);

    // save
    const onSave = async (values) => {
        setSubmitting(true);

        let claims = fields.map((o, i) => {
            return { name: o.name, value: values[o.name] ?? "" };
        });

        if (values.customerNumber) claims.customernumber = values.customerNumber;
        if (values.credentialNumber) claims.credentialNumber = values.credential;

        let args = {
            id: parseInt(id, 10),
            status: values.status,
            expiresOn: values.expiresOn,
            notes: values.notes,
            claimData: JSON.stringify(claims),
        };

        await credentialService
            .saveVerification(args)
            .then((data) => {
                notify.success(`Verification was saved`, () => navigate(`/home`));
            })
            .catch((err) => {
                notify.error(err);
            })
            .finally(() => setSubmitting(false));
    };

    const formatLabel = (s) => {
        if (s === "firstname") s = "first name";
        if (s === "lastname") s = "last name";
        s = s.replace(/[-_.,]/g, " ");
        return s;
    };

    return (
        <Card
            title="Manage Verification"
            extra={
                <Button color="btn btn-light" onClick={() => navigate("/home")} title="All Queue">
                    View Queue
                </Button>
            }
        >
            {!loading && item?.user ? (
                <div>
                    <Alert type="info" message="Please fill in any applicable information" className="mb-4" />
                    <Descriptions title="Verification" bordered column={{ sm: 2, xs: 1 }} className="mb-4">
                        <Descriptions.Item label="User">{item.user.fullName}</Descriptions.Item>
                        <Descriptions.Item label="Email">{item.user.email}</Descriptions.Item>
                        <Descriptions.Item label="Agency">{item.credentialAgencyName}</Descriptions.Item>
                        <Descriptions.Item label="Certification">{item.credentialCertificationName}</Descriptions.Item>
                    </Descriptions>

                    <Form name="mainForm" form={form} layout="vertical" onFinish={onSave}>
                        <Form.Item name="customerNumber" label="Customer Number">
                            <Input />
                        </Form.Item>

                        <Form.Item name="credentialNumber" label="Credential Number">
                            <Input />
                        </Form.Item>

                        {fields.map((o, i) => {
                            return (
                                <Form.Item key={i} name={o.name} label={formatLabel(o.name)}>
                                    <Input />
                                </Form.Item>
                            );
                        })}

                        <Form.Item name="expiresOn" label="Expiration Date">
                            <DatePicker />
                        </Form.Item>

                        <Form.Item name="status" label="Status" rules={[{ required: true, message: "please choose a status" }]}>
                            <Radio.Group
                                optionType="button"
                                buttonStyle="solid"
                                options={[
                                    { label: "Verified", value: 2 },
                                    { label: "Not Verified", value: 3 },
                                    { label: "Expired", value: 4 },
                                ]}
                            ></Radio.Group>
                        </Form.Item>

                        <Form.Item name="notes" label="Notes">
                            <Input />
                        </Form.Item>

                        <Form.Item>
                            <Button type="primary" htmlType="submit" loading={submitting}>
                                Submit
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            ) : (
                <div>
                    <Skeleton active />
                </div>
            )}
        </Card>
    );
}

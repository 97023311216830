import axios from "./AxiosHelper";

// define public methods
export const credentialService = {
    tokenLogin,

    searchVerifications,
    getVerification,
    saveVerification,
};

function tokenLogin(token) {
    return axios.getData(`/api/credential/tokenlogin/${token}`);
}

function searchVerifications(data) {
    return axios.postData(`/api/credential/verifications/search`, data, true);
}

function getVerification(id) {
    return axios.getData(`/api/credential/verifications/${id}`, true);
}

function saveVerification(data) {
    return axios.upsertData(data.id, `/api/credential/verifications`, data, true);
}

import React from "react";
import { Link } from "react-router-dom";
import { Layout } from "antd";
const { Content, Header } = Layout;

const SimpleLayout = (props) => {
    return (
        <Layout className="wrapper">
            <Layout>
                <Header className="navbar">
                    <Link to={"/"} className="navbar-logo-container">
                        <img src="/assets/images/logo.png" className="navbar-logo" alt="logo" />
                    </Link>
                    <div>Credential Verification</div>
                </Header>
                <Content className="container">{props.children}</Content>
            </Layout>
        </Layout>
    );
};

export default SimpleLayout;
